import debounce from 'lodash/debounce'
import { useLibrariesStore } from '@/store/libraries'
import type {
  Country,
  Language,
  Timezone,
  Currency,
  Location,
  LibraryItem,
  Status,
  ApiResponse,
  Outcome,
} from '@/types'

import type { DICTIONARIES } from '~/constants'

// TODO: refactor libraries

export const useCountries = debounce(
  async () => {
    const librariesStore = useLibrariesStore()
    if (librariesStore.getCountries?.length) {
      return librariesStore.getCountries
    } else {
      const { data, error } = await useAPIFetch('/countries?fields=id,name,phone_code,iso2,timezones')
      if (error.value) throw createError(error.value)

      const library = ((data?.value as ApiResponse)?.data as Country[]) || []
      librariesStore.setCountries(library)
      return library
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useLanguages = debounce(
  async () => {
    const librariesStore = useLibrariesStore()
    if (librariesStore.getLanguages?.length) {
      return librariesStore.getLanguages
    } else {
      const { data, error } = await useAPIFetch('/languages')
      if (error.value) throw createError(error.value)
      const library = ((data?.value as ApiResponse)?.data as Language[]) || []
      librariesStore.setLanguages(library)
      return library
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useTimezones = debounce(
  async () => {
    const librariesStore = useLibrariesStore()
    if (librariesStore.getTimezones?.length) {
      return librariesStore.getTimezones
    } else {
      const { data, error } = await useAPIFetch('/timezones')
      if (error.value) throw createError(error.value)
      const library = ((data?.value as ApiResponse)?.data as Timezone[]) || []
      librariesStore.setTimezones(library)
      return library
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useCreationLeadStrategies = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getStrategies?.length) {
      return librariesStore.getStrategies
    } else {
      const { data, error } = await useAPIFetch('/dictionary/save_strategy')
      if (error.value) throw createError(error.value)
      const library = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setStrategies(library)
      return library
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useSourceLeadStrategies = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getSourceLeadStrategies?.length) {
      return librariesStore.getSourceLeadStrategies
    } else {
      const { data, error } = await useAPIFetch('/dictionary/update_lead_source_details_strategies')
      if (error.value) throw createError(error.value)
      const library = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setSourceLeadStrategies(library)
      return library
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useCurrencies = debounce(
  async () => {
    const librariesStore = useLibrariesStore()
    if (librariesStore.getCurrencies?.length) {
      return librariesStore.getCurrencies
    } else {
      const { data, error } = await useAPIFetch('/currencies?fields=id,name,code')
      if (error.value) throw createError(error.value)
      const library = ((data?.value as ApiResponse)?.data as Currency[]) || []
      librariesStore.setCurrencies(library)
      return library
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useInterests = debounce(
  async () => {
    const librariesStore = useLibrariesStore()
    if (librariesStore.getInterests?.length) {
      return librariesStore.getInterests
    } else {
      const { data, error } = await useAPIFetch('/dictionary/interests')
      if (error.value) throw createError(error.value)
      const library = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setInterests(library)
      return library
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useLocations = debounce(
  async () => {
    const librariesStore = useLibrariesStore()
    if (librariesStore.getLocations?.length) {
      return librariesStore.getLocations
    } else {
      const { data, error } = await useAPIFetch('/dictionary/locations/')
      if (error.value) throw createError(error.value)
      const library = ((data.value as any).data as Location[]) || []
      librariesStore.setLocations(library)
      return library
    }
  },
  200,
  { leading: true, trailing: false }
)

export const usePropertyTypes = debounce(
  async () => {
    const librariesStore = useLibrariesStore()
    if (librariesStore.getPropertyTypes?.length) {
      return librariesStore.getPropertyTypes
    } else {
      const { data, error } = await useAPIFetch('/dictionary/estate_types/')
      if (error.value) throw createError(error.value)
      const library = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setPropertyTypes(library)
      return library
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useUserStatuses = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getStatuses?.length) {
      return librariesStore.getStatuses
    } else {
      const { data, error } = await useAPIFetch('/dictionary/user_statuses')
      if (error.value) throw createError(error.value)
      const library = ((data.value as any).data as Status[]) || []
      librariesStore.setStatuses(library)
      return library
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useBedrooms = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getBedrooms?.length) {
      return librariesStore.getBedrooms
    } else {
      const { data, error } = await useAPIFetch('/dictionary/bedrooms')
      if (error.value) throw createError(error.value)
      const library = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setBedrooms(library)
      return library
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useResolutions = debounce(
  async () => {
    const librariesStore = useLibrariesStore()
    if (librariesStore.getResolutions?.length) {
      return librariesStore.getResolutions
    } else {
      const { data, error } = await useAPIFetch('/dictionary/resolutions')
      if (error.value) throw createError(error.value)
      const library = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setResolutions(library)
      return library
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useDashboardRanges = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getDashboardRanges?.length) {
      return librariesStore.getDashboardRanges
    } else {
      const { data, error } = await useAPIFetch('/dictionary/dashboard_statistics_period')
      if (error.value) throw createError(error.value)
      const library = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setDashboardRanges(library)
      return library
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useCalendarActivityTypes = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getCalendarActivityTypes?.length) {
      return librariesStore.getCalendarActivityTypes
    } else {
      const { data, error } = await useAPIFetch('/dictionary/calendar_activity_types')
      if (error.value) throw createError(error.value)
      const library = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setCalendarActivityTypes(library)
      return library
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useCalendarActivityStatuses = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getCalendarActivityStatuses?.length) {
      return librariesStore.getCalendarActivityStatuses
    } else {
      const { data, error } = await useAPIFetch('/dictionary/calendar_activity_statuses')
      if (error.value) throw createError(error.value)
      const library = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setCalendarActivityStatuses(library)
      return library
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useNotificationTypes = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getNotificationTypes?.length) {
      return librariesStore.getNotificationTypes
    } else {
      const { data, error } = await useAPIFetch('/dictionary/user_notification_types')
      if (error.value) throw createError(error.value)
      const library = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setNotificationTypes(library)
      return library
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useCallOutboundOutcomes = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getCallOutboundOutcomes?.length) {
      return librariesStore.getCallOutboundOutcomes
    } else {
      const { data, error } = await useAPIFetch('/dictionary/outbound_call_outcomes')
      if (error.value) throw createError(error.value)
      const outcomesTypes = ((data.value as any).data as Outcome[]) || []
      librariesStore.setCallOutboundOutcomes(outcomesTypes)

      return outcomesTypes
    }
  },
  200,
  { leading: true, trailing: false }
)

export const usePbxCallDirections = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getPbxCallDirections?.length) {
      return librariesStore.getPbxCallDirections
    } else {
      const { data, error } = await useAPIFetch('/dictionary/pbx_call_directions')
      if (error.value) throw createError(error.value)
      const directions = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setPbxCallDirections(directions)

      return directions
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceDealStages = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceDealStages?.length) {
      return librariesStore.getFinanceDealStages
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_deal_stages')
      if (error.value) throw createError(error.value)
      const stages = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceDealStages(stages)

      return stages
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceDealStatuses = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceDealStatuses?.length) {
      return librariesStore.getFinanceDealStatuses
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_deal_statuses')
      if (error.value) throw createError(error.value)
      const statuses = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceDealStatuses(statuses)

      return statuses
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceDealSources = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceDealSources?.length) {
      return librariesStore.getFinanceDealSources
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_deal_sources')
      if (error.value) throw createError(error.value)
      const sources = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceDealSources(sources)

      return sources
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceDealTypes = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceDealTypes?.length) {
      return librariesStore.getFinanceDealTypes
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_deal_types')
      if (error.value) throw createError(error.value)
      const types = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceDealTypes(types)

      return types
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceDealChannels = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceDealChannels?.length) {
      return librariesStore.getFinanceDealChannels
    } else {
      const { data, error } = await useAPIFetch('/dictionary/lead_channel_names')
      if (error.value) throw createError(error.value)
      const types = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceDealChannels(types)

      return types
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceDealReasons = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceDealReasons?.length) {
      return librariesStore.getFinanceDealReasons
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_deal_reasons')
      if (error.value) throw createError(error.value)
      const dealReasons = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceDealReasons(dealReasons)

      return dealReasons
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceDealActionTypes = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceDealActionTypes?.length) {
      return librariesStore.getFinanceDealActionTypes
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_deal_action_types')
      if (error.value) throw createError(error.value)
      const dealActionTypes = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceDealActionTypes(dealActionTypes)

      return dealActionTypes
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceDealActionStatuses = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceDealActionStatuses?.length) {
      return librariesStore.getFinanceDealActionStatuses
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_deal_action_statuses')
      if (error.value) throw createError(error.value)
      const dealActionStatuses = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceDealActionStatuses(dealActionStatuses)

      return dealActionStatuses
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceProjects = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceProjects?.length) {
      return librariesStore.getFinanceProjects
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_projects')
      if (error.value) throw createError(error.value)
      const projects = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceProjects(projects)

      return projects
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceDevelopers = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceDevelopers?.length) {
      return librariesStore.getFinanceDevelopers
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_developers')
      if (error.value) throw createError(error.value)
      const developers = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceDevelopers(developers)

      return developers
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceBuyers = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceBuyers?.length) {
      return librariesStore.getFinanceBuyers
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_buyers')
      if (error.value) throw createError(error.value)
      const buyers = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceBuyers(buyers)

      return buyers
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceFileTypes = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceFileTypes?.length) {
      return librariesStore.getFinanceFileTypes
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_file_types')
      if (error.value) throw createError(error.value)
      const fileTypes = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceFileTypes(fileTypes)

      return fileTypes
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceCommissionTypes = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceCommissionTypes?.length) {
      return librariesStore.getFinanceCommissionTypes
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_commission_types')
      if (error.value) throw createError(error.value)
      const commissionTypes = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceCommissionTypes(commissionTypes)

      return commissionTypes
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceCommissionStatuses = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceCommissionStatuses?.length) {
      return librariesStore.getFinanceCommissionStatuses
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_commission_statuses')
      if (error.value) throw createError(error.value)
      const commissionStatuses = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceCommissionStatuses(commissionStatuses)

      return commissionStatuses
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceAgreementTypes = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceAgreementTypes?.length) {
      return librariesStore.getFinanceAgreementTypes
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_agreement_types')
      if (error.value) throw createError(error.value)
      const types = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceAgreementTypes(types)

      return types
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceAgreementStatuses = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceAgreementStatuses?.length) {
      return librariesStore.getFinanceAgreementStatuses
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_agreement_statuses')
      if (error.value) throw createError(error.value)
      const statuses = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceAgreementStatuses(statuses)

      return statuses
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinancePaymentInvoiceStatuses = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinancePaymentInvoiceStatuses?.length) {
      return librariesStore.getFinancePaymentInvoiceStatuses
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_payment_invoice_statuses')
      if (error.value) throw createError(error.value)
      const statuses = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinancePaymentInvoiceStatuses(statuses)
      return statuses
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceCommissionCalculationTypes = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceCommissionCalculationTypes?.length) {
      return librariesStore.getFinanceCommissionCalculationTypes
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_commission_calculation_types')
      if (error.value) throw createError(error.value)
      const types = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceCommissionCalculationTypes(types)
      return types
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useSpamStatuses = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getSpamStatuses?.length) {
      return librariesStore.getSpamStatuses
    } else {
      const { data, error } = await useAPIFetch('/dictionary/pbx_number_spam_statuses')
      if (error.value) throw createError(error.value)
      const statuses = ((data.value as any).data as LibraryItem[]) || []

      librariesStore.setSpamStatuses(statuses)
      return statuses
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useSpamServices = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getSpamServices?.length) {
      return librariesStore.getSpamServices
    } else {
      const { data, error } = await useAPIFetch('/dictionary/pbx_number_spam_services')
      if (error.value) throw createError(error.value)
      const statuses = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setSpamServices(statuses)
      return statuses
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useActivityActionsTaken = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getActivityActionsTaken?.length) {
      return librariesStore.getActivityActionsTaken
    } else {
      const { data, error } = await useAPIFetch('/dictionary/activity_actions_taken')
      if (error.value) throw createError(error.value)
      const actions = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setActivityActionsTaken(actions)
      return actions
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceContactTypes = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceContactTypes?.length) {
      return librariesStore.getFinanceContactTypes
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_contact_types')
      if (error.value) throw createError(error.value)
      const types = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceContactTypes(types)
      return types
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useUserAvailabilityStatuses = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getUsersAvailabilityStatuses?.length) {
      return librariesStore.getUsersAvailabilityStatuses
    } else {
      const { data, error } = await useAPIFetch('/dictionary/user_settings_availability_statuses')
      if (error.value) throw createError(error.value)
      const statuses = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setUsersAvailabilityStatuses(statuses)
      return statuses
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceCommissionGroupStatuses = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceCommissionsGroupStatuses?.length) {
      return librariesStore.getFinanceCommissionsGroupStatuses
    } else {
      const { data, error } = await useAPIFetch('/dictionary/finance_commission_group_statuses')
      if (error.value) throw createError(error.value)
      const statuses = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceCommissionsGroupStatuses(statuses)
      return statuses
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useFinanceDealVerificationsStatuses = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getFinanceDealVerificationsStatuses?.length) {
      return librariesStore.getFinanceDealVerificationsStatuses
    } else {
      const { data, error } = await useAPIFetch('/dictionary/verification_statuses')
      if (error.value) throw createError(error.value)
      const statuses = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setFinanceDealVerificationsStatuses(statuses)
      return statuses
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useMeetingOutcomeStatuses = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getMeetingOutcomeStatuses?.length) {
      return librariesStore.getMeetingOutcomeStatuses
    } else {
      const { data, error } = await useAPIFetch('/dictionary/meeting_outcome_statuses')
      if (error.value) throw createError(error.value)
      const statuses = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setMeetingOutcomeStatuses(statuses)
      return statuses
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useMeetingOutcomeResolutions = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getMeetingOutcomeResolutions?.length) {
      return librariesStore.getMeetingOutcomeResolutions
    } else {
      const { data, error } = await useAPIFetch('/dictionary/meeting_outcome_resolutions')
      if (error.value) throw createError(error.value)
      const resolutions = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setMeetingOutcomeResolutions(resolutions)
      return resolutions
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useLeadPhoneTypes = debounce(async () => {
  const librariesStore = useLibrariesStore()

  if (librariesStore.getLeadPhoneTypes?.length) {
    return librariesStore.getLeadPhoneTypes
  } else {
    const { data, error } = await useAPIFetch('/dictionary/lead_phone_via_types')
    if (error.value) throw createError(error.value)
    const types = ((data.value as any).data as LibraryItem[]) || []

    librariesStore.setLeadPhoneTypes(types)

    return types
  }
})

export const useDashboardReports = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getDashboardReports?.length) {
      return librariesStore.getDashboardReports
    } else {
      const { data, error } = await useAPIFetch('/dictionary/report_types')
      if (error.value) throw createError(error.value)
      const reports = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setDashboardReports(reports)
      return reports
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useReleaseComponentTypes = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getReleaseComponentTypes?.length) {
      return librariesStore.getReleaseComponentTypes
    } else {
      const { data, error } = await useAPIFetch('/dictionary/release_component_types')
      if (error.value) throw createError(error.value)
      const types = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setReleaseComponentTypes(types)
      return types
    }
  },
  200,
  { leading: true, trailing: false }
)

export const usePaymentTokenTypes = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getPaymentTokenTypes?.length) {
      return librariesStore.getPaymentTokenTypes
    } else {
      const { data, error } = await useAPIFetch('/dictionary/payment_token_types')
      if (error.value) throw createError(error.value)
      const types = ((data.value as any).data as LibraryItem[]) || []
      const result = types.map((item: LibraryItem) => {
        return { value: item.id, text: item.name, code: item.code }
      })
      librariesStore.setPaymentTokenTypes(result)
      return result
    }
  },
  200,
  { leading: true, trailing: false }
)

export const usePCDictionary = async (entity: DICTIONARIES) => {
  const { $api } = useNuxtApp()
  const librariesStore = useLibrariesStore()
  const config = useRuntimeConfig()

  // Simple cache
  if (librariesStore.propertyCatalogLibraries[entity]) {
    return {
      data: librariesStore.propertyCatalogLibraries[entity],
      status: 'success',
      message: 'Success',
    }
  }

  const response = await $api<ApiResponse<LibraryItem[]>>(`dictionary/${entity}`, {
    baseURL: config.public.API_URL_PROPERTY_CATALOGUE,
    method: 'GET',
  })

  if (response.data) {
    librariesStore.propertyCatalogLibraries[entity] = response.data
  }

  return response
}

export const useMappingRotationTypes = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getMappingRotationTypes?.length) {
      return librariesStore.getMappingRotationTypes
    } else {
      const { data, error } = await useAPIFetch('/dictionary/rotation_types')
      if (error.value) throw createError(error.value)
      const types = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setMappingRotationTypes(types)
      return types
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useUtmSources = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getUtmSources?.length) {
      return librariesStore.getUtmSources
    } else {
      const { data, error } = await useAPIFetch('/leads/channels')
      if (error.value) throw createError(error.value)
      const types = ((data.value as any).data as string[]) || []
      const channels: LibraryItem[] = types.map((item: string) => {
        return { name: item, id: item }
      })
      librariesStore.setUtmSources(channels)
      return channels
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useCallHandlers = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getCallHandlers?.length) {
      return librariesStore.getCallHandlers
    } else {
      const { data, error } = await useAPIFetch('/dictionary/call_queue_handlers')
      if (error.value) throw createError(error.value)
      const types = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setCallHandlers(types)
      return types
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useLocalPhoneTypes = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getLocalPhoneTypes?.length) {
      return librariesStore.getLocalPhoneTypes
    } else {
      const { data, error } = await useAPIFetch('/dictionary/local_phone_types')
      if (error.value) throw createError(error.value)
      const types = ((data.value as any).data as LibraryItem[]) || []
      librariesStore.setLocalPhoneTypes(types)
      return types
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useGetPropertyProjects = debounce(
  async () => {
    const librariesStore = useLibrariesStore()

    if (librariesStore.getPropertyProjects?.length) {
      return librariesStore.getPropertyProjects
    } else {
      const { data, error } = await useAPIPropertiesFetch(`properties/select`)

      if (error.value) throw createError(error.value)

      const projects: LibraryItem[] = Object.entries(data.value.data)
        .filter(([_, value]) => value !== null)
        .map(([key, value]) => ({
          id: key,
          name: value as string,
        }))
      librariesStore.setPropertyProjects(projects)
      return projects
    }
  },
  200,
  { leading: true, trailing: false }
)

export const useDictionary = async (entity: DICTIONARIES, serialize = true) => {
  const librariesStore = useLibrariesStore()

  // Simple cache
  if (librariesStore.libraries[entity]) {
    return {
      data: librariesStore.libraries[entity],
      status: 'success',
      message: 'Success',
    }
  }

  const response = await useAPIFetch<ApiResponse<LibraryItem[]>>(`dictionary/${entity}`)

  const dictionary = serialize ? useSerializeLibraryItems(response.data.value.data) : response.data.value.data

  if (response.data.value) {
    librariesStore.libraries[entity] = dictionary
  }

  return dictionary
}
