import { FEATURE_FLAGS } from '~/constants'

export const useFeatureFlag = (key: FEATURE_FLAGS) => {
  // const runtimeConfig = useRuntimeConfig()

  const flags = {
    [FEATURE_FLAGS.PAYMENT_TOKENS]: true,
  }

  return flags[key]
}
